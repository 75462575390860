import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader";
import { makePostRequest } from "../../api/apiHandler";
import mpesalogo from "./Mpesalogo.jpg";
import './wizard.css';
import { viewCartItems } from "../cart/cartSlice";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const Step1 = ({ setCurrentStep }:any) => {
    const [loading, setloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageVisible, setSuccessVisible] = useState(false);
    const [phone, setPhone] = useState('');
    const [method, setPaymentMethod] = useState('Mpesa');

    const history = useHistory();
    

    const dispatch = useDispatch();

    useEffect(() => {
      setCurrentStep(1);
      dispatch(viewCartItems());
    }, [dispatch]);

    const state:any = useSelector((state) => state);
    const cart = state.cart;
    const items = cart.items;

    const initiatePayment = async () => {
        setloading(true);

        const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/checkout/pay`, {
            phoneNumber:phone,
            transactionType: method
        })

        if(result.status === 200){
            setloading(false);
            setErrorMessageVisible(false)
            setSuccessVisible(true);
            setSuccessMessage(result.payload.CustomerMessage + ". Complete payment then click continue.")
            toast.success('Successfully initiated!');

        }else{
            setloading(false);
            setSuccessVisible(false);
            setErrorMessage('Payment was not successful please try initiating again');
            setErrorMessageVisible(true);
            toast.error('Payment unsuccessful!');
        }
    }

    const handleChange = (no) => {
      setPhone(no);
    }

    const handleRadioChange = (event) => {
      setPaymentMethod(event.target.value)
      console.log(event.target.value);

    }

    const goToNext = () => {
        history.push("/checkout/step2");
    }

    return(
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <Toaster/>
            <h3 className="font-weight-bold text-dark mb-5">Payment details</h3>
            <div className="text-dark-50 line-height-lg card pl-5 pr-5 mb-5">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="pl-0 font-weight-bold text-muted text-uppercase">Items</th>
                      <th className="text-right font-weight-bold text-muted text-uppercase">Qty</th>
                      <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    { items.length > 0 && items.map((item, index) => {
                        return(
                          <tr key={ index } className="font-weight-boldest border-bottom-0">
                            <td className="border-top-0 pl-0 py-4 d-flex align-items-center">
                            {item.product.name}</td>
                            <td className="border-top-0 text-right py-4 align-middle">{item.quantity}</td>
                            <td className="text-primary border-top-0 pr-0 py-4 text-right align-middle">Ksh. {item.product.price * item.quantity}</td>
                          </tr>
                        )
                      })
                    }
                    {!(items.length > 0) && <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
															<div className="alert-icon">
																<span className="svg-icon svg-icon-primary svg-icon-xl">
																{/*begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Tools/Compass.svg*/}
																<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<rect x={0} y={0} width={24} height={24} />
																	<path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
																	<path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
																	</g>
																</svg>
																{/*end::Svg Icon*/}
																</span>
															</div>
															<div className="alert-text">You have no products in cart! Continue Shopping and add!
																{/* <a className="font-weight-bold" href="http://bootstrap-notify.remabledesigns.com/" target="_blank">Home</a>or 
																<a className="font-weight-bold" href="https://github.com/mouse0270/bootstrap-notify/" target="_blank">Github Repo</a>.</div> */}
															</div>
															</div>
															}
                    <tr className="font-weight-boldest border-bottom-0">
                      <td className="border-0 pt-0"></td>
                      <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right">Total</td>
                      <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-primary pr-0 text-right">Ksh. {cart.totalAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>            
            <form className="form">
                <div className="form-group row">
                  <label className="col-12 col-form-label">Payment method</label>
                  <div className="col-12 col-form-label">
                    <div className="radio-inline">
                      <label className="radio radio-outline radio-primary">
                        <input 
                          type="radio" 
                          name="pmethod"
                          value="Mpesa"
                          checked={method === 'Mpesa'}
                          onChange={handleRadioChange}
                        />
                        <span />
                        Mpesa
                      </label>
                      <label className="radio radio-outline radio-primary">
                        <input 
                        type="radio" 
                        name="method"
                        value="COD"
                        checked={method === 'COD'}
                        onChange={handleRadioChange}
                        />
                        <span />
                        Cash On delivery
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            {method === 'Mpesa' && <div>
            <img src={mpesalogo} className="mpesalogo mb-5 mt-n5" alt="Mpesa logo"/>
            <p>The proces is so simple initiate payment</p>
            <p>1. Initiate Payment</p>
            <p>2. Wait for an STK push from safaricom</p>
            <p>3. Enter your pin to confirm the payment</p>
            <div className="form-group fv-plugins-icon-container">
                    <label className="text-primary">Phone number</label>
                    <input 
                      onChange={(input) => handleChange(input.target.value)}
                    type="text" className="form-control form-control-solid form-control-lg w-50" name="package" />
                    <span className="form-text text-muted">Please enter phone number to initate payment.</span>
                    <div className="fv-plugins-message-container" /></div>
            {loading ? <Loader/> : <button type="button" id="kt_login_signup" className="btn btn-outline-primary btn-pill py-4 px-9 font-weight-bold mt-5" 
            onClick={initiatePayment}
            >Initiate payment</button>}
            {errorMessageVisible && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10 mt-10">
                  {/*begin::Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column pe-0 pe-sm-10">
                    {/*begin::Title*/}
                    <h4 className="fw-bold text-danger">Something went wrong!</h4>
                    {/*end::Title*/}
                    {/*begin::Content*/}
                    <span>{errorMessage}</span>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Close*/}
                  {/*end::Close*/}
                </div>}

                {successMessageVisible && <div className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-5 mb-10 mt-10">
                  {/*begin::Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column pe-0 pe-sm-10">
                    {/*begin::Title*/}
                    <h4 className="fw-bold text-success">Success!</h4>
                    {/*end::Title*/}
                    {/*begin::Content*/}
                    <span>{successMessage}</span>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Close*/}
                  {/*end::Close*/}
                 </div>}
            </div> }

            {method === 'COD' && <div>
            {/* <img src={mpesalogo} className="mpesalogo mb-5 mt-n5" alt="Mpesa logo"/> */}
            <p>For Cash on delivery payment, you are eligible to pay an <br /> 
              initial payment amounting to 10% of the amount due. This helps us cater
              for dispatch and delivery fees. Thanks!
            </p>
            <div className="form-group fv-plugins-icon-container">
                    <label className="text-primary">Phone number</label>
                    <input 
                      onChange={(input) => handleChange(input.target.value)}
                    type="text" className="form-control form-control-solid form-control-lg w-50" name="package" />
                    <span className="form-text text-muted">Please enter phone number to initate payment.</span>
                    <div className="fv-plugins-message-container" /></div>
            {loading ? <Loader/> : <button type="button" id="kt_login_signup" className="btn btn-outline-primary btn-pill py-4 px-9 font-weight-bold mt-5" 
            onClick={initiatePayment}
            >Initiate payment</button>}
            {errorMessageVisible && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10 mt-10">
                  {/*begin::Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column pe-0 pe-sm-10">
                    {/*begin::Title*/}
                    <h4 className="fw-bold text-danger">Something went wrong!</h4>
                    {/*end::Title*/}
                    {/*begin::Content*/}
                    <span>{errorMessage}</span>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Close*/}
                  {/*end::Close*/}
                </div>}

                {successMessageVisible && <div className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-5 mb-10 mt-10">
                  {/*begin::Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column pe-0 pe-sm-10">
                    {/*begin::Title*/}
                    <h4 className="fw-bold text-success">Success!</h4>
                    {/*end::Title*/}
                    {/*begin::Content*/}
                    <span>{successMessage}</span>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Close*/}
                  {/*end::Close*/}
                 </div>}
            </div> }

            {successMessageVisible && <div className="d-flex justify-content-between">
            <div></div>
            <button 
            onClick={() => goToNext()}
            type="button" className="btn btn-primary">Continue</button>
            </div>}
            <br/>
            <br/>
        </div>
    );
}

export default Step1;