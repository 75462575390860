import React from 'react';
import MobProdCard from '../../../common/products/Mobprodcard';
import ProductsPage from '../../product/ProductsPage';

const MobProductsContainer = () => {
    return(
        <div className="">
            <ProductsPage/>
        </div>
    );
}

export default MobProductsContainer;