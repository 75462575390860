import { createSlice } from '@reduxjs/toolkit';
import { makePostRequest } from '../../api/apiHandler';

export const slice = createSlice({
  name: 'currentHomepage',
  initialState: {
    currentHomepage: ''
  },
  reducers: {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

    setCurrentHomepage: (state, action) => {
        state.currentHomepage = action.payload
    } 
  },
});

export const {setCurrentHomepage} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


// export const addToCart = productID => async dispatch => {
//     dispatch(cartPending())
//     const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/addToCart`,
//         {
//             productId:productID
//         }
//     )

//     if(result.status === 200){
//        dispatch(cartSuccess(result.payload.products));
//        dispatch(setTotals());
//     }else{
//         dispatch(cartFailed());
//     }
// }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const currentHomepage = state => state.currentHomepage.currentHomepage;

export default slice.reducer;
