
import React, { useEffect } from 'react';
import './Firstpage.css';

import Hardware from './hardware.svg';
import Wholesale  from './wholesale.svg';
import Logo from './main-logom.png';
import {Link } from 'react-router-dom'
import './dist/css/style.css';
import './dist/css/custom.css';
import logoLight from './dist/images/logo-light.svg';
import logoDark from './dist/images/logo-dark.svg';
import headerIllustrationLight from './dist/images/header-illustration-light.svg';
import headerIllustrationDark from './dist/images/header-illustration-dark.svg';
import headerHeroIllustrationLight from './dist/images/hero-media-illustration-light.svg';
import headerHeroIllustrationDark from './dist/images/hero-media-illustration-dark.svg';
import headerHeroMediaLight from './dist/images/hero-img.svg';
import headerHeroMediaDark from './dist/images/hero-media-dark.svg';
import featureIllustrationDark from './dist/images/features-illustration-dark.svg';
import featuresBoxDark from './dist/images/features-box-dark.svg';
import featuresIllustrationTopDark from './dist/images/features-illustration-top-dark.svg';
import featureIllustrationLight from './dist/images/features-illustration-light.svg';
import featuresBoxLight from './dist/images/features-box-light.svg';
import featuresIllustrationTopLight from './dist/images/features-illustration-top-light.svg';
import feature1Light from './dist/images/feature-01-light.svg';
import featuresBoxLight2 from './dist/images/features-box-light2.svg';
import feature1Dark from './dist/images/feature-01-dark.svg';
import feature2Light from './dist/images/feature-02-light.svg';
import feature2Dark from './dist/images/feature-02-dark.svg';
import feature3Light from './dist/images/feature-03-light.svg';
import feature3Dark from './dist/images/feature-03-dark.svg';
import { useMixpanel } from 'react-mixpanel-browser';

const FirstPage = () => {

  const mixpanel = useMixpanel();

  useEffect(() => {
    if(mixpanel.config.token){
        mixpanel.track('FirstPage', {
            property:'landed'
        })
    }    
  }, [])

  const wsClick = () => {
    if(mixpanel.config.token){
        mixpanel.track('WholesaleOption', {
            property:'clicked'
        })  
    }
  }

  const hwClick = () => {
    if(mixpanel.config.token){
        mixpanel.track('HardwareOption', {
            property:'clicked'
        })
    }
  }

    return(
    <div className="">
      <div className="is-boxed has-animations is-loaded" style={{height: '100%'}}>
      <div className="body-wrap boxed-container">
        <header className="site-header">
          <div className="container">
            <div className="site-header-inner">
              <div className="brand header-brand">
                <h1 className="m-0">
                  <a href="#">
                    <img className="header-logo-image asset-light" src={logoLight} alt="Logo" />
                    <img className="header-logo-image asset-dark" src={logoDark} alt="Logo" />
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="logo-container pl-7 pt-5">
            <img className="logo-image" src={Logo} alt="logo" />
        </div>
        <main>
          <section className="hero">
            <div className="container">
              <div className="hero-inner">
                <div className="hero-copy">
                  <h1 className="hero-title mt-0">Welcome to Kairop</h1>
                  <p className="hero-paragraph">Your onestop Wholesale and Hardware online marketplace. Start by selecting any of the options below;</p>
                  <div className="hero-cta">
                    <Link to ="/wholesale">
                      <div 
                      onClick={() => wsClick()}
                      className="button button-primary">wholesale</div>
                    </Link>
                    <Link to ="/hardware">
                      <div 
                      onClick={() => hwClick()}
                      className="button button-primary button-infos">Hardware</div>
                    </Link>
                  </div>
                </div>
                <div className="hero-media">
                  <div className="header-illustration">
                    <img className="header-illustration-image asset-light" src={headerIllustrationLight} alt="Header illustration" />
                    <img className="header-illustration-image asset-dark" src={headerIllustrationDark} alt="Header illustration" />
                  </div>
                  <div className="hero-media-illustration">
                    <img className="hero-media-illustration-image asset-light" src={headerHeroIllustrationLight} alt="Hero media illustration" />
                    <img className="hero-media-illustration-image asset-dark" src={headerHeroIllustrationDark} alt="Hero media illustration" />
                  </div>
                  <div className="hero-media-container">
                    <img className="hero-media-image asset-light hero-container" src={headerHeroMediaLight} alt="Hero media" />
                    <img className="hero-media-image asset-dark" src={headerHeroMediaDark} alt="Hero media" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="features section">
            <div className="container">
              <div className="features-inner section-inner has-bottom-divider">
                <div className="features-header text-center">
                  <div className="container-sm">
                    <h2 className="section-title mt-0">Wholesale</h2>
                    <p className="section-paragraph">Get all your wholesale products.</p>
                    <Link to ="/wholesale">
                    <div className="features-image">
                      <img className="features-illustration asset-dark" src={featureIllustrationDark} alt="Feature illustration" />
                      <img className="features-box asset-dark" src={featuresBoxDark} alt="Feature box" />
                      <img className="features-illustration asset-dark" src={featuresIllustrationTopDark} alt="Feature illustration top" />
                      <img className="features-illustration asset-light" src={featureIllustrationLight} alt="Feature illustration" />
                      <img className="features-box asset-light" src={featuresBoxLight} alt="Feature box" />
                      <img className="features-illustration asset-light" src={featuresIllustrationTopLight} alt="Feature illustration top" />
                    </div>
                    </Link>
                  </div>
                </div>
                <div className="features-header text-center">
                  <div className="container-sm">
                    <h2 className="section-title mt-0">Hardware</h2>
                    <p className="section-paragraph">Get all your hardware products.</p>
                    <Link to ="/hardware">
                    <div className="features-image">
                      <img className="features-illustration asset-dark" src={featureIllustrationDark} alt="Feature illustration" />
                      <img className="features-box asset-dark" src={featuresBoxDark} alt="Feature box" />
                      <img className="features-illustration asset-dark" src={featuresIllustrationTopDark} alt="Feature illustration top" />
                      <img className="features-illustration asset-light" src={featureIllustrationLight} alt="Feature illustration" />
                      <img className="features-box asset-light" src={featuresBoxLight2} alt="Feature box" />
                      <img className="features-illustration asset-light" src={featuresIllustrationTopLight} alt="Feature illustration top" />
                    </div>
                    </Link>
                  </div>
                </div>
                <h1 className="hero-title mt-0">Why shop with us?</h1>
                <br />
                <div className="features-wrap">
                  <div className="feature is-revealing" style={{visibility: 'visible'}}>
                    <div className="feature-inner">
                      <div className="feature-icon">
                        <img className="asset-light" src={feature1Light} alt="Feature 01" />
                        <img className="asset-dark" src={feature1Dark} alt="Feature 01" />
                      </div>
                      <div className="feature-content">
                        <h3 className="feature-title mt-0">One Stop Shopping</h3>
                        <p className="text-sm mb-0">Our goal is to carry everything to satisfy your playground shopping needs. With over a thousand products in various stores.</p>
                      </div>
                    </div>
                  </div>
                  <div className="feature is-revealing" style={{visibility: 'visible'}}>
                    <div className="feature-inner">
                      <div className="feature-icon">
                        <img className="asset-light" src={feature2Light} alt="Feature 02" />
                        <img className="asset-dark" src={feature2Dark} alt="Feature 02" />
                      </div>
                      <div className="feature-content">
                        <h3 className="feature-title mt-0">Save Money With Guaranteed Low Prices</h3>
                        <p className="text-sm mb-0">It is our intent to provide the lowest prices anywhere on the items we sell. Just another reason why Kairop truly provides one-stop shopping.</p>
                      </div>
                    </div>
                  </div>
                  <div className="feature is-revealing" style={{visibility: 'visible'}}>
                    <div className="feature-inner">
                      <div className="feature-icon">
                        <img className="asset-light" src={feature3Light} alt="Feature 03" />
                        <img className="asset-dark" src={feature3Dark} alt="Feature 03" />
                      </div>
                      <div className="feature-content">
                        <h3 className="feature-title mt-0">Convenient Customer Service</h3>
                        <p className="text-sm mb-0">When you call or email us, Nothing is too much trouble. ‘No’ is hardly ever the answer. We will ensure that we take care of all the details of your order or request.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cta section">
            <div className="container-sm">
              <div className="cta-inner section-inner">
                <div className="cta-header text-center">
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      </div>
        </div>
    );
}

export default FirstPage;

