import React, {useState} from 'react';
import './Mobilenav.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const MobileNav = () => {


    const[homeActive, setHomeActive] = useState('');
    const[shopsActive, setShopsActive] = useState('');
    const[savedActive, setSavedActive] = useState('');
    const[cartActive, setCartActive] = useState('');
    const[accountActive, setAccountActive] = useState('');

    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage;


    
    const updateNav = (path) =>{
        if(path === "wholesale" || path === "hardware"){
            setHomeActive('home-active');
        }else{
            setHomeActive('');
        }

        if(path === "shops"){
            setShopsActive('shops-active');
        }else{
            setShopsActive('');
        }

        if(path === "saved"){
            setSavedActive('saved-active');
        }else{
            setSavedActive('');
        }

        if(path === "cart"){
            setCartActive('cart-active');
        }else{
            setCartActive('');
        }

        if(path === "account"){
            setAccountActive('account-active');
        }else{
            setAccountActive('');
        }
    }

    return(
        <div className="d-flex align-items-center justify-content-between nav-conatiner p-3 bg-dark">
            <Link to ={currentHomepage}>
            <div 
            onClick={() => updateNav(currentHomepage)}
            className={"text-center d-flex align-items-center justify-content-center icon-text-wrapper " + homeActive}>
                <div>
                    <i className="la fa-home text-primary icon-xl"></i>
                    <span className="text-small text-primary">Home</span>
                </div>
            </div>
            </Link>
            <Link to ="/shops">
                <div 
                onClick={() => updateNav("shops")}
                className={"text-center d-flex align-items-center justify-content-center icon-text-wrapper " + shopsActive}>
                    <div>
                        <i className="la la-store icon-secondary icon-xl"></i><br></br>
                        <span className="text-small text-light-secondary">Shops</span>
                    </div>
                </div>
            </Link>

            <Link to ="/cart">

            <div 
            onClick={() => updateNav("saved")}
            className={"text-center d-flex align-items-center justify-content-center icon-text-wrapper " + savedActive}>
                <div>
                    <i className="la la-heart-o text-danger icon-xl"></i><br></br>
                    <span className="text-small text-danger">Saved</span>
                </div>
            </div>
            </Link>

            <Link to ="/cart">
                <div 
                onClick={() => updateNav("cart")}
                className={"text-center d-flex align-items-center justify-content-center icon-text-wrapper " + cartActive}>
                <div className="cart-tag pulse pulse-primary">
                    {state.cart.totalItems}
                </div>
                    <div>
                        <i className="la fab la-opencart text-success icon-xl"></i><br></br>
                        <span className="text-small text-success">Cart</span>
                    </div>
                </div>
            </Link>
            
            <Link to ="/profile">
            <div 
            onClick={() => updateNav("account")}
            className={"text-center d-flex align-items-center justify-content-center icon-text-wrapper " + accountActive}>
                <div>
                <i className="la la-user text-info icon-xl"></i><br></br>
                <span className="text-small text-info">Account</span>
                </div>
            </div>
            </Link>
        </div>
    );
}

export default MobileNav;

