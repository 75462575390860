import React from 'react';
import './Mobshopcardsm.css';
import { isDesktop } from 'react-device-detect';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

  

const Mobshopcardsm = (shop:any) => {


    const {id, name, profileImageUrl, description} = shop.shop;

    const state:any = useSelector((state) => state);
    const history = useHistory();
    const currentHomepage = state.currentHomepage.currentHomepage;

    let col:string = "";
    if(isDesktop){
        col = "col-4";
    }

    const handleClick = () => {
        history.push(`/shop/${id}`, '/');
    }

    return(
        <div className={col + " card-wrapper"}>
            <div className="card card-custom gutter-b ">
                        
            <div className="card-body pt-4">	
                <div className="d-flex align-items-center mb-7">
                    
                    <div className="flex-shrink-0 mr-4">
                        <div className="symbol symbol-circle symbol-lg-75">
                            <img src={profileImageUrl || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJlGj1_TumdEDX1Y1R8Bj4ysH1j1CkH_3W-A&usqp=CAU'} alt="image" />
                        </div>
                    </div>
                    
                    <div className="d-flex flex-column">
                        <div onClick={handleClick} className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{name}</div>
                        <span className="text-muted font-weight-bold">{currentHomepage} shop</span>
                    </div>
                    
                </div>
                <p className="mb-7">{ description || "We deal with wide range of products like cement, building accessories e.t.c"}
                <a href="#" className="text-primary pr-1">#xrs-54pq</a></p>
                
                <div className="flex-row-fluid mb-7">
                    <span className="d-block font-weight-bold mb-4">Customer Satisfaction</span>
                    <div className="d-flex align-items-center pt-2">
                        <div className="progress progress-xs mt-2 mb-2 w-100">
                            <div className="progress-bar bg-warning" role="progressbar" style={{width: "90%"}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                        <span className="ml-3 font-weight-bolder">90%</span>
                    </div>
                </div>
                <div onClick={handleClick} className="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4">Visit Shop</div>
            </div>
            
        </div>
        </div>
    );
}

export default Mobshopcardsm;

