import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'selectedProduct',
  initialState: {
    selectedProduct:{

    }
  },
  reducers: {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

    setSelectedProduct: (state, action) => {
        state.selectedProduct = action.payload;
    } 
  },
});

export const {setSelectedProduct} = slice.actions;


export default slice.reducer;
