import { configureStore } from '@reduxjs/toolkit';
import productsSlice from '../App/common/products/productsSlice';
import shopsSlice from '../App/common/shops/shopsSlice';
import cartSlice from '../App/pages/cart/cartSlice';
import homepageSlice from '../App/pages/home-page/homepageSlice';

export default configureStore({
  reducer: {
    cart:cartSlice,
    currentHomepage: homepageSlice,
    selectedShop:shopsSlice,
    selectedProduct:productsSlice
  },
});
