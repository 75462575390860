import React, { useState, useEffect } from "react";
import { makePostRequest } from "../../api/apiHandler";
import { useSelector } from 'react-redux';
import Loader from "../../../Loader";
import { useHistory } from "react-router-dom";


const Orders = () => {


    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');
    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage;

    const history = useHistory();

    const [orders, setOrders] = useState<any>(
        [
            
        ]
    );

    useEffect(() => {

        setLoading(true);

        (async () => {

            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/checkout/view-orders`,
                {
                    
                }
            )
           
            if(result.status === 200){

              let orders = result?.payload?.orders

              if(orders.length > 0){
                  setOrders(orders);
              }else{
                  setErrorMessage('You have no orders yet!');
              }
                
                setError(false);
                setLoading(false);
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage('You have no orders yet!. Continue shopping!');
            }


        })()
    
        return () => {
            
        }
      }, [])

  const onSelectOrder = (order:any) => {
    localStorage.setItem('order', JSON.stringify(order));
    history.push("/orderdetails");
  }

    return(
        <div className="p-5 d-flex flex-wrap justify-content-between col-lg-12">
  <div className="card card-custom col-lg-12">
  <div className="card card-custom card-shadowless gutter-b">
  {/*begin::Header*/}
  <div className="card-header border-0 py-5">
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label font-weight-bolder text-dark">My Orders</span>
      <span className="text-muted mt-3 font-weight-bold font-size-sm">View your orders</span>
    </h3>
    <div className="card-toolbar">

    </div>
  </div>
  {/*end::Header*/}
  {/*begin::Body*/}
  <div className="card-body pt-0 pb-3">
    <div className="tab-content">
      {/*begin::Table*/}
      <div className="table-responsive">
        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
          <thead>
            <tr className="text-left text-uppercase">
              <th style={{minWidth: '80px'}} className="pl-7">
                <span className="text-dark-75">ORDER</span>
              </th>
              <th style={{minWidth: '100px'}}>Amt. Paid</th>
              <th style={{minWidth: '100px'}}>Amt. Due</th>
              <th style={{minWidth: '100px'}}>Payment ref</th>
              <th style={{minWidth: '100px'}}>Dispatch status</th>
              <th style={{minWidth: '130px'}}>Delivery status</th>
              <th style={{minWidth: '100px'}}>Payment status</th>
              <th style={{minWidth: '100px'}}>Order Date</th>
              <th style={{minWidth: '100px'}}></th>
            </tr>
          </thead>
          <tbody>
            
            {  (!error && orders.length > 0) &&
               orders.map((item, index) => {
                   return(
                    <tr key ={index}
                      onClick={() => onSelectOrder(item)}
                    >
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{item?.id}</a>
                          <span className="text-muted font-weight-bold d-block">Order no.</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className=" font-weight-bolder d-block text-primary font-size-lg">Ksh. {item?.amountPaid}</span>
                      <span className="text-muted font-weight-bold">Amount paid</span>
                    </td>
                    <td>
                      <span className=" font-weight-bolder d-block text-primary font-size-lg">Ksh. {item?.totalAmountDue}</span>
                      <span className="text-muted font-weight-bold">Amount due</span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item?.mpesaRef}</span>
                      <span className="text-muted font-weight-bold">mpesa code</span>
                    </td>
                    <td>
                      {item?.dispatched ? <span className="label label-lg label-light-success label-inline">Dispatched</span>
                      : <span className="label label-lg label-light-primary label-inline">Pending</span>
                      }
                    </td>
                    <td>
                      {item?.delivered ? <span className="label label-lg label-light-success label-inline">Delivered</span>
                      : <span className="label label-lg label-light-primary label-inline">Not Delivered</span>
                      }
                    </td>
                    <td>
                      {item?.paid ? <span className="label label-lg label-light-success label-inline">Paid</span>
                      : <span className="label label-lg label-light-danger label-inline">Not paid</span>
                      }
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item?.timeStamp}</span>
                      <span className="text-muted font-weight-bold">Order date</span>
                    </td>
                    <td className="pr-0 text-right">
                      <button  
                      onClick={() => onSelectOrder(item)}
                      className="btn btn-light-primary font-weight-bolder font-size-sm">View Order</button>
                    </td>
                  </tr>
                   );
               })
               
            }
          </tbody>
        </table>
      </div>
      {/*end::Table*/}
    </div>
  </div>
  {/*end::Body*/}
</div>


<div className="p-10">
{loading && <Loader />}
           {error && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                            <h4 className="fw-bold text-danger">Something went wrong</h4>
                            {/*end::Title*/}
                            {/*begin::Content*/}
                        <span>{errorMessage}</span>
                        {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>}
                {/*end::Alert*/}
            </div>
        </div>
    </div>
    );
    
}

export default Orders;

