import React from 'react';
import './Mobprodcard.css';
import { isDesktop } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MobProdCard = (product) => {
    

    const { id, name, price, url } = product.product;

    const state:any = useSelector((state) => state);
    const history = useHistory();
    const currentHomepage = state.currentHomepage.currentHomepage;
    

    let col:string = "col-6";
    if(isDesktop){
        col = "col-3";
    }

    const handleClick = () => {
        localStorage.setItem("shopPath", history.location.pathname);
        history.push(`/product/${id}`, '/');
    }

    return(
        <div 
            onClick={handleClick}
            className={col + " p-2 prod-card-container"}>
                <div className="card card-custom shadow-sm">
                    <div className="card-body p-3">
                        <img className="img-style rounded mb-1 prod-img" src={url} alt="Product"/>
                        <p className="text-dark-75 font-size-sm font-weight-normal pt-2 mb-2  block-with-text">{name} </p>
                        <div className="font-size-h6 text-primary font-weight-bolder prod-amt">Ksh. {price}</div>
                    </div>
                </div>
        </div>
    );
}

export default MobProdCard;

