import React, { useState } from "react";
import Logo from './main-logom.png';
import './Desktopnav.css';
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";


const Desktopnav = () => {

    const[homeActive, setHomeActive] = useState('');
    const[shopsActive, setShopsActive] = useState('');
    const[savedActive, setSavedActive] = useState('');
    const[cartActive, setCartActive] = useState('');
    const[accountActive, setAccountActive] = useState('');

    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage;

    const updateNav = (path) =>{
        if(path === "wholesale" || path === "hardware"){
            setHomeActive('active');
        }else{
            setHomeActive('');
        }

        if(path === "shops"){
            setShopsActive('active');
        }else{
            setShopsActive('');
        }

        if(path === "saved"){
            setSavedActive('active');
        }else{
            setSavedActive('');
        }

        if(path === "cart"){
            setCartActive('active');
        }else{
            setCartActive('');
        }

        if(path === "account"){
            setAccountActive('active');
        }else{
            setAccountActive('');
        }
    }

    return(
        <div className="card p-10">
             <div className="mt-1 mb-1">
            <div className="example-preview">
            <ul className="nav nav-pills nav-fill justify-content-between">
            <img src={Logo} className="max-h-40px mr-10" alt="" />
                <div className="d-flex justify-content-center align-items-center">
                <Link to ={currentHomepage}>
                <div 
                onClick={() => updateNav(currentHomepage)}
                className="nav-item">
                <div className={"nav-link justify-content-center " + homeActive }
                id="home-tab-4" data-toggle="tab" >
                    <span className="nav-icon">
                    <i className="la fa-home icon-xl"></i>
                    </span>
                    <span className="nav-text">Home</span>
                </div>
                </div>
                </Link>
                <Link to ="/shops">
                    <li 
                    onClick={() => updateNav("shops")}
                    className="nav-item">
                    <div className={"nav-link justify-content-center " + shopsActive} id="contact-tab-4" data-toggle="tab" aria-controls="contact">
                        <div className="d-flex justify-content-center align-items-center">
                        <span className="nav-icon">
                        <i className="la la-store icon-xl"></i>
                        </span>
                        <span className="nav-text">Shops</span>
                        </div>
                    </div>
                    </li>
                </Link>
                <Link to ="/cart">
                    <li 
                    onClick={() => updateNav("saved")}
                    className="nav-item">
                    <div className={"nav-link justify-content-center " + savedActive} id="contact-tab-4" data-toggle="tab" aria-controls="contact">
                        <div className="d-flex justify-content-center align-items-center">
                        <span className="nav-icon">
                        <i className="la la-heart-o icon-xl"></i>
                        </span>
                        <span className="nav-text">Saved</span>
                        </div>
                    </div>
                    </li>
                </Link>
                <Link to ="/cart">
                    <li 
                    onClick={() => updateNav("cart")}
                    className="nav-item">
                    <div className={"nav-link justify-content-center " + cartActive} id="contact-tab-4" data-toggle="tab" aria-controls="contact">
                        <div className="d-flex justify-content-center align-items-center">
                        <span className="nav-icon">
                        <i className="la la-opencart icon-xl"></i>
                        </span>
                        <span className="nav-text">Cart</span>
                        </div>
                    </div>
                    </li>
                </Link>

                <Link to ="/profile">
                    <li 
                    onClick={() => updateNav("account")}
                    className="nav-item">
                    <div className={"nav-link justify-content-center " + accountActive} id="contact-tab-4" data-toggle="tab" aria-controls="contact">
                        <div className="d-flex justify-content-center align-items-center">
                        <span className="nav-icon">
                        <i className="la la-user icon-xl"></i>
                        </span>
                        <span className="nav-text">Account</span>
                        </div>
                    </div>
                    </li>
                </Link>
                </div>
            </ul>
            </div>

        </div>
        </div>
    );
}

export default  Desktopnav;