import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Loader from '../../../Loader';
import { makePostRequest } from '../../api/apiHandler';
import MobProdCard from '../../common/products/Mobprodcard';
import Mobshopcardsm from '../../common/shops/Mobshopcardsm';

export interface urlParams {
    query: string;
} 

const Searchresults = () => {

    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');
    const { query }:urlParams  = useParams();

    const [products, setProducts] = useState(
        [
           
        ]
    );

    useEffect(() => {

        setLoading(true);

        (async () => {

            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/products/searchItems`,
                {
                    name: query,
                }
            )
           
            if(result.status === 200){
                setError(false);
                setProducts(result.payload.products);
                if(!(result.payload.products.length > 0)){
                    setError(true);
                    setErrorMessage('No result found!'); 
                }
                setLoading(false);
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage('Failed to get Products. Please try again!');
            }

        })()
    
        return () => {
            
        }
      }, [])

    return(
        <div className="card card-custom">
            <div className="card-header flex-wrap border-0 mt-10 pt-6 pb-0">
                <div className="card-title">
                    <h2 className="card-label">{query} Search results
                    <span className="d-block text-muted pt-2 font-size-md">Some of the products found</span></h2>
                </div>
                <div className="card-toolbar">
                </div>
                </div>

             <div className="p-5 d-flex flex-wrap justify-content-between">     
           {(!error && products.length > 0) &&
               products.map((item, index) => {
                   return(
                    <MobProdCard key={index} product={item}/>
                   );
               })
           }
           {loading && <Loader />}
           {error && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                            <h4 className="fw-bold text-danger">Something went wrong</h4>
                            {/*end::Title*/}
                            {/*begin::Content*/}
                        <span>{errorMessage}</span>
                        {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>}
                {/*end::Alert*/}
        </div>
        <h2 className="card-label ml-10">Related shops
                    <span className="d-block text-muted pt-2 font-size-md">Some of the shops conaining {query}</span></h2>
        <div className="d-flex flex-wrap justify-content-between shop-cards-container p-5">
            {(!error && products.length > 0) &&
                products.map((item, index) => {
                    return(
                        <Mobshopcardsm key={index} shop={item}/>
                    );
                })
            }
            {loading && <Loader />}
           {error && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                            <h4 className="fw-bold text-danger">Something went wrong</h4>
                            {/*end::Title*/}
                            {/*begin::Content*/}
                        <span>{errorMessage}</span>
                        {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>}
                {/*end::Alert*/}
        </div>
        </div>
    );
}

export default Searchresults;