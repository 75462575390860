import React, { useState } from 'react';
import logo from './logo-white.png';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MobProductsContainer from '../home-page/mobile/Mobproductscontainer';

const TopProducts = () => {

    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage;
    const[searchInput, setSearchInput] = useState('')

    const handleInputChange = (input) => {
        setSearchInput(input);
    }

    const history = useHistory();

    const search = () => {
        history.push(`/search/${searchInput}`, '/');
    }

    return(
        <div className="">
            <div className="card card-custom card-stretch gutter-b">
            
                <div className="card-body p-0 position-relative overflow-hidden">
                    
                    <div className="">
                        <div className="card-header bg-transparent border-0 py-5">
                        </div>
                        <div className="p-10">
                        <div className="quick-search quick-search-inline" id="kt_quick_search_inline">
                            <form method="get" className="quick-search-form">
                                    <div className="input-group rounded bg-light">
                                        <div onClick={() => search()} className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="svg-icon svg-icon-lg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                                                            <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                        <input type="text" className="form-control h-40px" placeholder="Search..." 
                                        onChange={(input => handleInputChange(input.target.value))}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="quick-search-close ki ki-close icon-sm text-muted" style={{display:"none"}}></i>
                                            </span>
                                        </div>
                                    </div>
                                </form>
                                <div id="kt_quick_search_toggle" data-toggle="dropdown" data-offset="0px,1px"></div>

                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-anim-up">
                                    <div className="quick-search-wrapper scroll ps" data-scroll="true" data-height="350" data-mobile-height="200" style={{height: "350px", overflow: "hidden"}}><div className="ps__rail-x" style={{left: "0px", bottom: "0px"}}><div className="ps__thumb-x" tabIndex={0} style={{left: "0px", width: "0px"}}></div></div><div className="ps__rail-y" style={{top: "0px", right: "0px"}}><div className="ps__thumb-y" tabIndex={0} style={{top: "0px", height: "0px"}}></div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                
                    
                <div className="resize-triggers"><div className="expand-trigger"><div style={{width: 414, height: 460}}></div></div><div className="contract-trigger"></div></div></div>
                
            </div>
            <h2 className="pl-4 d-flex mt-2">Top {currentHomepage} Products <span className="label label-danger label-inline ml-2">new</span></h2>
            <MobProductsContainer />
            <br />
            <br />
            <br />
            <br />
            <br />            
        </div>                   
    );
}

export default TopProducts;

