import React, { useState, useEffect } from "react";
import { makePostRequest } from "../../api/apiHandler";
import './Productview.css';
import { useHistory, useParams } from "react-router-dom";
import { 
    addToCart,
} from "../cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import Mobiletopnav from "../../common/navs/Mobiletopnav";
import Alert from "../../common/components/Alert";


export interface urlParams {
    productId:string
} 

const Productview = () => {

    const dispatch = useDispatch()
    const state:any = useSelector((state) => state);

    let history = useHistory();

    const cart = state.cart;

    console.log(cart);

    const { productId }:urlParams  = useParams();

    const [product, setProducts] = useState<any>(
        {

        }
    );

    useEffect(() => {

        (async () => {

            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/products/listProducts`,
                {
                    id:Number(productId)
                }
            )
           
            if(result.status === 200){
                setProducts(result.payload);
            }

        })()
    
        return () => {
            
        }
      }, [])

    const addProduct = (id) => {
        const userStatus = localStorage.getItem('loggedIn');
        
        if(userStatus !== 'true'){
            localStorage.setItem("beforeAuthPath", history.location.pathname);
            history.push('/signin');
        }else{
            dispatch(addToCart(id));
            history.push('/cart');
        }

        
    }

    return(
        <div>
            <div className="p-5 d-flex flex-wrap justify-content-between">
            <div className="card card-custom card-stretch gutter-b" style={{width:"100%"}}>
                <div className="card-body p-15 pb-20">
                    <div className="row mb-17">
                    <div className="col-xl-5 mb-11 mb-xxl-0">
                        {/*begin::Image*/}
                        <div className="card card-custom card-stretch">
                        <div className="card-body p-0 rounded px-10 py-15 d-flex align-items-center justify-content-center">
                            <img src={product.url} className="rounded mw-100 w-200px" style={{transform: 'scale(1.6)'}} alt="Product pic"/>
                        </div>
                        </div>
                        {/*end::Image*/}
                    </div>
                    <div className="col-xl-7 pl-xxl-11">
                        <h2 className="font-weight-bolder text-dark mb-7" style={{fontSize: '32px'}}>{product.name}</h2>
                        <div className="font-size-h2 mb-7 text-dark-50">
                        <span className="text-primary font-weight-boldest text-large" style={{fontSize: '28px'}}>Ksh. {product.price}</span></div>
                        <div className="line-height-xl">{product.description}</div>
                        <div className="d-flex">
                            <button type="button" className="btn btn-primary font-weight-bolder mr-6 px-6 font-size-sm w-200px h-50px mt-10"
                                onClick={() => addProduct(product.id)}
                            >
                                <div className="d-flex align-items-center">
                                <span className="svg-icon w-30px">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                    <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                                </span><span style={{fontSize: '15px'}}>Add to cart</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    </div>
                    <div className="row mb-6">
                    {/*begin::Info*/}
                    <div className="col-6 col-md-4">
                        <div className="mb-8 d-flex flex-column">
                        <span className="text-dark font-weight-bold mb-4">Category</span>
                        {product?.category?.name && <span className="text-muted font-weight-bolder font-size-lg">{product?.category?.name}</span>}
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="mb-8 d-flex flex-column">
                        <span className="text-dark font-weight-bold mb-4">Color</span>
                        <span className="text-muted font-weight-bolder font-size-lg">Normal</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="mb-8 d-flex flex-column">
                        <span className="text-dark font-weight-bold mb-4">Brand</span>
                        <span className="text-muted font-weight-bolder font-size-lg">Generic</span>
                        </div>
                    </div>
                    {/*end::Info*/}
                    </div>
                </div>
                </div>
        
        </div>
        </div>
    );
}

export default Productview;

