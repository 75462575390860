import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { environment } from '../../../environment/environment';
import Loader from '../../../Loader';
import { makePostRequest } from '../../api/apiHandler';
import toast, { Toaster } from 'react-hot-toast';
import { useMixpanel } from 'react-mixpanel-browser';

const Signup = () => {

	const [stepLoading, setStepLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
	let history = useHistory();
	const mixpanel = useMixpanel();
	const [personalInfo, setPersonalInfo] = useState(
        {
            firstName:'',
            lastName:'',
            email:'',
            password1:'',
            password2:'',
            telephone1:'',
            telephone2:'',
            postalAddress:'',
            token:''
        }
      )

	const handleInputChange = (event) => {

		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
  
		setPersonalInfo(prevState => ({
		  ...prevState,
		  [name]: value
		  }))
	  }

	function validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	const submit = async () => {

		if(!personalInfo.firstName){
			toast.error('Enter your first name!');
			return;
		}

		if(!personalInfo.lastName){
			toast.error('Enter your last name!');
			return;
		}

		if(!validateEmail(personalInfo.email)){
			toast.error('Invalid email address!');
			return;
		}

		if(personalInfo.password1.length < 8 || personalInfo.password2.length < 8){
			toast.error('Password must be 8 or more characters!');
			return;
		}

		if(personalInfo.password1 !== personalInfo.password2){
			toast.error('Passwords must match!');
			return;
		}

		// if(personalInfo.telephone1.length < 10 || personalInfo.telephone2.length < 10){
		// 	toast.error('Invalid telephone number!');
		// 	return;
		// }

		if(!personalInfo.postalAddress){
			toast.error('Enter postal adress!');
			return;
		}

		setStepLoading(true);

		if(mixpanel.config.token){
            mixpanel.identify(personalInfo.email);

            mixpanel.people.set({ 
                email: personalInfo.email,
				firstName:personalInfo.firstName,
            	lastName:personalInfo.lastName,
				username:personalInfo.email,
				telephone1:personalInfo.telephone1,
				telephone2:personalInfo.telephone2,
				postalAddress:personalInfo.postalAddress,
				token:personalInfo.email
            });

            mixpanel.track('userEmail', {
                property:personalInfo.email
            });

			mixpanel.track('firstname', {
                property:personalInfo.firstName
            });

			mixpanel.track('lastname', {
                property:personalInfo.lastName
            });
          }

        const result:IApiResponse = await makePostRequest(`${environment.CORE_API_URL}/auth/clientSignUp`,
          {
            firstName:personalInfo.firstName,
            lastName:personalInfo.lastName,
            username:personalInfo.email,
            email:personalInfo.email,
            authToken:personalInfo.password1,
            telephone1:personalInfo.telephone1,
            telephone2:personalInfo.telephone2,
            postalAddress:personalInfo.postalAddress,
            token:personalInfo.email
          }
        )

		if(result.status === 200){
			if(result.payload.email){
				setStepLoading(false);
				setErrorMessageVisible(false);
				localStorage.setItem("SKEY", result.payload.token);
				localStorage.setItem("loggedIn", "true");
				localStorage.setItem('userInfo', JSON.stringify(result.payload));
				history.push(localStorage.getItem("beforeAuthPath"));
			}else{
				setStepLoading(false);
				setErrorMessageVisible(true)
              	setErrorMessage('Something went wrong. Please try again');
			}
		  }else{
			setErrorMessageVisible(true)
			setErrorMessage('Something went wrong. Please try again');
			setStepLoading(false);
		}
	}

    return(
        <div className="d-flex flex-column flex-root mb-20 p-5">
           <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
				{/*begin::Signup*/}
				<Toaster/>
				<div className="login-form login-signup">
					<div className="text-center mb-10 mb-lg-20">
					<h3 className="font-size-h1">Sign Up</h3>
					<p className="text-muted font-weight-bold">Enter your details to create your account</p>
					</div>
					{/*begin::Form*/}
					<form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_login_signup_form">
					 {/*begin::Form Group*/}
					 <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">First Name</label>
                  <input type="text" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="firstName" placeholder="First Name" 
                    onChange={handleInputChange}
                    value={personalInfo.firstName}
					/>
					</div>
					{/*end::Form Group*/}
					{/*begin::Form Group*/}
					<div className="form-group">
					<label className="font-size-h6 font-weight-bolder text-dark">Last Name</label>
					<input type="text" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="lastName" placeholder="Last Name" 
					onChange={handleInputChange}
					value={personalInfo.lastName}
					/>
					</div>
					{/*end::Form Group*/}
					{/*begin::Form Group*/}
					<div className="form-group">
					<label className="font-size-h6 font-weight-bolder text-dark">Email</label>
					<input type="email" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="email" placeholder="Email" 
					onChange={handleInputChange}
					value={personalInfo.email}
					/>
					</div>
					{/*end::Form Group*/}
					{/*begin::Form Group*/}
					<div className="form-group">
					<label className="font-size-h6 font-weight-bolder text-dark">Password</label>
					<input type="password" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="password1" placeholder="password" 
					onChange={handleInputChange}
					value={personalInfo.password1}
					/>
					</div>
					<div className="form-group">
					<label className="font-size-h6 font-weight-bolder text-dark">Repeat Password</label>
					<input type="password" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="password2" placeholder="repeat password" 
					onChange={handleInputChange}
					value={personalInfo.password2}
					/>
					</div>
					{/*end::Form Group*/}
							{/*begin::Row*/}
			<div className="row">
				<div className="col-xl-6">
				{/*begin::Input*/}
				<div className="form-group">
					<label className="font-size-h6 font-weight-bolder text-dark">Postal address</label>
					<input type="text" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="postalAddress" placeholder="Postal"  
					onChange={handleInputChange}
					value={personalInfo.postalAddress}
					/>
				</div>
				{/*end::Input*/}
				</div>
				<div className="col-xl-6">
				{/*begin::Input*/}
				<div className="form-group">
					<label className="font-size-h6 font-weight-bolder text-dark">Telephone number</label>
					<input type="text" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="telephone1" placeholder="Phone number "  
					onChange={handleInputChange}
					value={personalInfo.telephone1}
					/>
				</div>
				{/*end::Input*/}
				</div>
			</div>
			{/*end::Row*/}
			{/*begin::Row*/}
			<div className="row">
				<div className="col-xl-6">
				{/*begin::Input*/}
				<div className="form-group">
					<label className="font-size-h6 font-weight-bolder text-dark">Alternative phone number</label>
					<input type="text" className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6" name="telephone2" placeholder="alternative-no" 
					onChange={handleInputChange}
					value={personalInfo.telephone2}
					/>
				</div>
				{/*end::Input*/}
				</div>
				
			</div>
					<div className="form-group fv-plugins-icon-container">
						<label className="checkbox mb-0">
						<input type="checkbox" name="agree" />
						<span /> I Agree the
						<div>
							<Link to="/termsandconditions">
								<div className="text-primary"> terms and conditions</div>
							</Link>
						</div>
						</label>
						<div className="fv-plugins-message-container" />
							{/*begin::Alert*/}
							{errorMessageVisible && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                                <h4 className="fw-bold text-danger">Something went wrong</h4>
                                {/*end::Title*/}
                                {/*begin::Content*/}
                                <span>{errorMessage}</span>
                                {/*end::Content*/}
                              </div>
                              {/*end::Wrapper*/}
                              {/*begin::Close*/}
                              {/*end::Close*/}
                            </div>}
                            {/*end::Alert*/}
						</div>
					<div className="form-group d-flex flex-wrap flex-center">
						{stepLoading ? <Loader/> :
							<button type="button" id="kt_login_signup_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
						onClick={() => submit()}
						>Submit</button>}
						<button type="button" id="kt_login_signup_cancel" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</button>
					</div>
					<div /></form>
					{/*end::Form*/}
				</div>
				{/*end::Signup*/}
			</div>
        </div>
    );
}

export default Signup;