import React, { useState, useEffect } from "react";
import Mobshopcardsm from '../../../common/shops/Mobshopcardsm';
import './Mobshopscontainer.css';
import { makePostRequest } from '../../../api/apiHandler';
import { useSelector } from "react-redux";
import Loader from "../../../../Loader";


const Mobshopscontainer = () => {

    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');
    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage.toUpperCase();

    const [shops, setShops] = useState(
        [

        ]
    );

    useEffect(() => {

        setLoading(true);

        (async () => {

            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/getShops`,
                {
                    shopType: currentHomepage
                }
            )
           
            if(result.status === 200){
                // setShops(result.payload.shops);

                setError(false);
                setShops(result.payload.shops);
                setLoading(false);
                // if((shops.length > 0) === false) {
                //     setError(true);
                //     setErrorMessage('No shops found!');
                // }
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage('Failed to get Shops. Please try again!');
            }

        })()
    
        return () => {
            
        }
      }, [])

    return(
        <div className="d-flex flex-wrap main-shops-card-container p-5">
            {(!error && shops.length > 0) &&
                shops.map((item, index) => {
                    if(item.active){
                        return(
                            <Mobshopcardsm key={index} shop={item}/>
                        );
                    }
                })
            }
            {loading && <Loader />}
           {error && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                            <h4 className="fw-bold text-danger">Something went wrong</h4>
                            {/*end::Title*/}
                            {/*begin::Content*/}
                        <span>{errorMessage}</span>
                        {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>}
                {/*end::Alert*/}
        </div>
    );
}

export default Mobshopscontainer;