import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { makePostRequest } from '../../api/apiHandler';
import Loader from '../../../Loader';
import MobProdCard from '../../common/products/Mobprodcard';

export interface urlParams {
    categoryID:string,
    categoryName:string
}

const Categoryview = () => {

    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');

    let history = useHistory();

    const { categoryID, categoryName }:urlParams  = useParams();

    const [products, setProducts] = useState(
        [
           
        ]
    );


    useEffect(() => {

        setLoading(true);
        
        (async () => {
            
            console.log(categoryID)
            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/products/listProducts`,
                {
                    category_id:Number(categoryID) 
                }                
            )
           
            if(result.status === 200){
                // setError(false);
                setProducts(result.payload.products);
                setLoading(false);
            }else{
                setLoading(false);
                // setError(true);
                // setErrorMessage('Failed to get Products. Please try again!');
            }

        })()
    
        return () => {
            
        }
      }, []);

    return(
        <div>
            <h1 className="p-5">{categoryName} CATEGORY</h1>
            <div className="p-5 d-flex flex-wrap justify-content-between">     
           {(!error && products.length > 0) &&
               products.map((item, index) => {
                   return(
                    <MobProdCard key={index} product={item}/>
                   );
               })
           }
           {loading && <Loader />}
           {error && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                            <h4 className="fw-bold text-danger">Something went wrong</h4>
                            {/*end::Title*/}
                            {/*begin::Content*/}
                        <span>{errorMessage}</span>
                        {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>}
                {/*end::Alert*/}
        </div>
        </div>
    );
}

export default Categoryview;

