import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Profile.css';

const Profile: React.FC = () => {

    const isLoggedIn = localStorage.getItem("loggedIn");
    const user = JSON.parse(localStorage.getItem("userInfo"));

    let history = useHistory();

    const signOut = () => {
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("SKEY");
        history.push('/profile');
    }

    const signIn = () => {
        localStorage.setItem("beforeAuthPath", history.location.pathname);
        history.push('/signin');
    }

    return(
        <div className="">
            {isLoggedIn === "true" && <div className="" id="">
                    {/*begin::Card*/}
                    <div className="card card-custom">
                        {/*begin::Body*/}
                        <div className="card-body pt-15">
                        {/*begin::User*/}
                        <div className="text-center mb-10">
                            <div className="symbol symbol-60 symbol-circle symbol-xl-90">
                            <div className="symbol-label" style={{backgroundImage: 'url("https://pngset.com/images/profile-icon-orange-symbol-soccer-ball-football-team-sport-transparent-png-1014106.png")'}} />
                            <i className="symbol-badge symbol-badge-bottom bg-success" />
                            </div>
                            <h4 className="font-weight-bold my-2">Welcome {user.firstName} {user.lastName}</h4>
                            <div className="text-muted mb-2">{user.email}</div>
                            <span className="label label-light-warning label-inline font-weight-bold label-lg">Active</span>
                        </div>
                        {/*end::User*/}
                        {/*begin::Nav*/}
                        <div>
                        <div className="d-flex justify-content-center">
                    <div className="d-flex mr-30">
                        <ul className="navi">
                        <Link to="/">
                          <li className="navi-item">
                            <div className="navi-link">
                            <span className="symbol symbol-50 symbol-circle mr-3">
                                <span className="symbol-label">
                                <i className="flaticon2-analytics text-danger" />
                                </span>
                            </span>
                            <span className="navi-text">Explore</span>
                            <span className="label label-light-danger font-weight-bold label-inline">new</span>
                            </div>
                        </li>  
                        </Link>
                        <Link to="/cart">
                         <li className="navi-item">
                            <div className="navi-link">
                            <span className="symbol symbol-50 symbol-circle mr-3">
                                <span className="symbol-label">
                                <i className="flaticon2-pie-chart-2 text-warning" />
                                </span>
                            </span>
                            <span className="navi-text">Cart</span>
                            </div>
                        </li>   
                        </Link>
                        <Link to="/orders">
                          <li className="navi-item">
                            <div className="navi-link" >
                            <span className="symbol symbol-50 symbol-circle mr-3">
                                <span className="symbol-label">
                                <i className="flaticon2-box-1 text-success" />
                                </span>
                            </span>
                            <span className="navi-text">My orders</span>
                            {/* <span className="navi-label">
                                <span className="label label-warning label-rounded">5</span>
                            </span> */}
                            </div>
                        </li>  
                        </Link>
                        <Link to="/favourites">
                           <li className="navi-item">
                            <div className="navi-link">
                            <span className="symbol symbol-50 symbol-circle mr-3">
                                <span className="symbol-label">
                                <i className="flaticon2-file text-primary" />
                                </span>
                            </span>
                            <span className="navi-text">Favourites</span>
                            </div>
                            </li> 
                        </Link>
                        </ul>
                        <div className="separator separator-dashed mt-4 mb-8" />
                    </div>
                    </div>
            <div 
            onClick={() => signOut()}
            className="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block mt-20">Sign Out</div>
            </div>
            <br /><br /><br />
            
            {/*end::Nav*/}
            </div>
            {/*end::Body*/}
        </div>
        {/*end::Card*/}
        </div>}
        {isLoggedIn !== "true" &&<div 
        onClick={() => signIn()}
        className="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block mt-20">Sign In to continue</div>}
        </div>
    );
}

export default Profile;

