import React, { useState, useEffect } from "react";
import { makePostRequest } from "../../api/apiHandler";
import MobProdCard from '../../common/products/Mobprodcard';
import { useSelector } from 'react-redux';
import Loader from "../../../Loader";
import Mobshopcardsm from "../../common/shops/Mobshopcardsm";


const Shopproducts = ({shopId}) => {


    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');
    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage;

    const [products, setProducts] = useState(
        [
            
        ]
    );

    useEffect(() => {

        setLoading(true);

        (async () => {

            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/products/listProducts`,
                {
                    shop_id:Number(shopId)
                }
            )
           
            if(result.status === 200){
                
                setError(false);
                setProducts(result.payload.products);
                setLoading(false);
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage('Failed to get Products. Please try again!');
            }


        })()
    
        return () => {
            
        }
      }, [])

    return(
        <div className="p-5 d-flex flex-wrap justify-content-between">
            
           {  (!error && products.length > 0) &&
               products.map((item, index) => {
                   return(
                        <MobProdCard key={index} product={item}/>
                   );
               })
           }
           {loading && <Loader />}
           {error && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                            <h4 className="fw-bold text-danger">Something went wrong</h4>
                            {/*end::Title*/}
                            {/*begin::Content*/}
                        <span>{errorMessage}</span>
                        {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>}
                {/*end::Alert*/}
        </div>
    );
}

export default Shopproducts;

