import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makePostRequest } from "../../api/apiHandler";

const Orderdetails = () => {

    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');
    const state:any = useSelector((state) => state);
    const[today, setToday] = useState('');
    const[total, setTotals] = useState(0);
    

    const [order, setOrder] = useState<any>(
        {

        }        
    );

    useEffect(() => {

        setLoading(true);
        const order = localStorage.getItem('order');
        const parserdOrder = JSON.parse(order);
        setOrder(parserdOrder);
        
      }, []);


    return (
        <div className="col-12">
            <div className="card card-custom gutter-b">
            <div className="card-body p-0">
                {/* begin: Invoice*/}
                {/* begin: Invoice header*/}
                <div className="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                <div className="col-md-10">
                    <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                    <h1 className="display-4 font-weight-boldest mb-10">ORDER DETAILS</h1>
                    <div className="d-flex flex-column align-items-md-end px-0">
                        {/*begin::Logo*/}
                        <a href="#" className="mb-5">
                        <img src="/metronic/theme/html/demo6/dist/assets/media/logos/logo-dark.png" alt="" />
                        </a>
                        {/*end::Logo*/}
                        <span className="d-flex flex-column align-items-md-end opacity-70">
                        <span>{order?.xuser?.email}</span>
                        <span>{order?.xuser?.firstName}</span>
                        <span>{order?.xuser?.telephone1}</span>
                        </span>
                    </div>
                    </div>
                    <div className="border-bottom w-100" />
                    <div className="d-flex justify-content-between pt-6">
                    <div className="d-flex flex-column flex-root">
                        <span className="font-weight-bolder mb-2">ORDER DATE</span>
                        <span className="opacity-70">{order.timeStamp}</span>
                    </div>
                    <div className="d-flex flex-column flex-root">
                        <span className="font-weight-bolder mb-2">RECEIPT NO.</span>
                        <span className="opacity-70">{order.mpesaRef}</span>
                    </div>
                    <div className="d-flex flex-column flex-root">
                        <span className="font-weight-bolder mb-2">DELIVERED TO.</span>
                        <span className="opacity-70">{order?.xuser?.postalDeliveryAddress}</span>
                    </div>
                    </div>
                </div>
                </div>
                {/* end: Invoice header*/}
                {/* begin: Invoice body*/}
                <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                <div className="col-md-10">
                    <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="pl-0 font-weight-bold text-muted text-uppercase">Ordered Items</th>
                            <th className="text-right font-weight-bold text-muted text-uppercase">Qty</th>
                            <th className="text-right font-weight-bold text-muted text-uppercase">Unit Price</th>
                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                         order?.products?.length > 0 && order.products.map((product, i) =>{
            
                            return (
                                <tr key={i} className="font-weight-boldest">
                                    <td className="border-0 pl-0 pt-7 d-flex align-items-center">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                        <div className="symbol-label" style={{backgroundImage: product.thumbUrl}} />
                                    </div>
                                    {/*end::Symbol*/}
                                    {product.name}</td>
                                    <td className="text-right pt-7 align-middle">{1}</td>
                                    <td className="text-right pt-7 align-middle">{product.price}</td>
                                    <td className="text-primary pr-0 pt-7 text-right align-middle">{product.price}</td>
                                </tr>
                            );
                        })
                        
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
                {/* end: Invoice body*/}
                {/* begin: Invoice footer*/}
                <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0">
                <div className="col-md-10">
                    <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="font-weight-bold text-muted text-uppercase">PAYMENT TYPE</th>
                            <th className="font-weight-bold text-muted text-uppercase">PAYMENT STATUS</th>
                            <th className="font-weight-bold text-muted text-uppercase text-right">TOTAL PAID</th>
                            <th className="font-weight-bold text-muted text-uppercase text-right">TOTAL DUE</th>
                            <th className="font-weight-bold text-muted text-uppercase">PAYMENT DATE</th>
                

                        </tr>
                        </thead>
                        <tbody>
                        <tr className="font-weight-bolder">
                            <td>Mpesa</td>
                            <td>Success</td>
                            <td className="text-primary font-size-h3 font-weight-boldest text-right">{order.amountPaid}</td>
                            <td className="text-primary font-size-h3 font-weight-boldest text-right">{order.totalAmountDue}</td>
                            <td>{order.timeStamp}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
                {/* end: Invoice footer*/}
                {/* begin: Invoice action*/}
                <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                <div className="col-md-10">
                    <div className="d-flex justify-content-between">
                    <Link to="/orders"><button type="button" className="btn btn-light-primary font-weight-bold">View my orders</button></Link>
                    <Link to="/"><button type="button" className="btn btn-primary font-weight-bold">Continue shopping</button></Link>
                    </div>
                </div>
                </div>
                <br />
                <br />
                <br />
                {/* end: Invoice action*/}
                {/* end: Invoice*/}
            </div>
            </div> 
        </div>
    );
}

export default Orderdetails;