import React from 'react';
import Mobshopcardsm from '../../common/shops/Mobshopcardsm';
import Mobshopscontainer from '../home-page/mobile/Mobshopscontainer';

const ShopsPage = () => {
    return(
        <div>
            <div className="p-5">
            <Mobshopscontainer/>
            </div>
        </div>
    );
}

export default ShopsPage;

