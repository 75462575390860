import React,{useEffect, Suspense, lazy} from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { setCurrentHomepage } from './homepageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import Loader from '../../../Loader';
const Mobview = lazy(() => import('./mobile/Mobview'));
const Desktopview = lazy(() => import('./desktop/Desktopview'));



const WholesaleHomepage: React.FC = () => {

    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage;
    const mixpanel = useMixpanel();

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setCurrentHomepage('wholesale'));
        if(mixpanel.config.token){
            mixpanel.track('WholesaleLanding', {
                property:'landed'
            })
        }
    }, [setCurrentHomepage]);


    return(
        <div>
            <MobileView>
            <Suspense fallback={<div className="d-flex flex-center vh-100"><Loader /></div>}>
                <Mobview />
            </Suspense>           
            </MobileView>

            <BrowserView>
            <Suspense fallback={<div className="d-flex flex-center vh-100"><Loader /></div>}>
                <Desktopview />
            </Suspense>  
            </BrowserView>        
        </div>
    );
}

export default WholesaleHomepage;

