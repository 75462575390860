import { createSlice, current } from '@reduxjs/toolkit';
import { makePostRequest } from '../../api/apiHandler';

export const slice = createSlice({
  name: 'items',
  initialState: {
    items: [
        
    ],
    totalItems:0,
    totalAmount:0,
    pending:false,
    successful:false,
    failed:false
  },
  reducers: {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

    cartPending: state => {
        state.successful = false;
        state.failed = false;
        state.pending = true;
    },
    cartSuccess: (state, action) => {
        state.failed = false;
        state.pending = false;
        state.successful = true;
        state.items = action.payload.cartItems;
        state.totalAmount = action.payload.amountDue;
        let tot = 0;
        action.payload.cartItems.forEach(item => {
            tot += item.quantity;
        });
        state.totalItems = tot;
    },
    cartFailed: state => {
        state.pending = false;
        state.successful = false;
        state.failed = true;
    },
    updateQuantity:(state, action) => {
        const item = state.items.find((item) => item.id === action.payload.id);
        if(item){
            item.quantity = Number(action.payload.input)
            if(action.payload.input === ''){
                item.quantity = '';
            }
        }
        console.log(current(state))
    },
    resetTotal:state => {
        state.totalAmount = 0;
    }
  },
});

export const { cartPending, cartFailed, cartSuccess, updateQuantity} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const viewCartItems = () => async dispatch => {
    dispatch(cartPending())
    const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/viewCart`,
        {
            
        }
    )

    if(result.status === 200){
       dispatch(cartSuccess(result.payload));
    }else{
        dispatch(cartFailed());
    }
}

export const addToCart = productID => async dispatch => {
    dispatch(cartPending())
    const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/addToCart`,
        {
            productId:productID
        }
    )

    if(result.status === 200){
       dispatch(cartSuccess(result.payload));
    }else{
        dispatch(cartFailed());
    }
}

export const updateCart = (input, cartID, productID) => async dispatch => {
    dispatch(updateQuantity({id:cartID, input}));
    dispatch(cartPending())
        if(input !== ''){
            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/addToCart`,
            {
                productId:productID,
                quantity:Number(input)
            }
        )

        if(result.status === 200){
        dispatch(cartSuccess(result.payload));
        }else{
            dispatch(cartFailed());
        }
    }else{
        dispatch(cartPending());
    }
}

export const removeFromCart = cartItemID => async dispatch => {
    dispatch(cartPending())
    const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/removeFromCart`,
        {
            itemID:cartItemID
        }
    )

    if(result.status === 200){
       dispatch(cartSuccess(result.payload));
    }else{
        dispatch(cartFailed());
    }
}


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default slice.reducer;
