import React from 'react';
import './App.css';
import { Route, Switch, Link } from 'react-router-dom';
import ShopsPage from './App/pages/shop/ShopsPage';
import ProductsPage from './App/pages/product/ProductsPage';
import Cart from './App/pages/cart/Cart';
import MobileNav from './App/common/navs/Mobilenav';
import FirstPage from './App/pages/first-page/Firstpage';
import { useLocation } from 'react-router-dom';
import HardwareHomepage from './App/pages/home-page/HadwareHomepage';
import WholesaleHomepage from './App/pages/home-page/WholesaleHomepage';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Shopview from './App/pages/shop/Shopview';
import Productview from './App/pages/product/Productview';
import Signup from './App/pages/auth/signup';
import Signin from './App/pages/auth/Signin';
import Checkout from './App/pages/checkout/Checkout';
import Searchresults from './App/pages/search-results/Searchresults';
import Profile from './App/pages/user/Profile';
import Orders from './App/pages/orders/Orders';
import TopProducts from './App/pages/product/TopProducts';
import Categoryview from './App/pages/category/Categoryview';
import Orderdetails from './App/pages/orders/Orderdetails';
import Desktopnav from './App/common/navs/Desktopnav';
import Termsandconds from './App/pages/termsandcons/Termsandconds';
import { MixpanelProvider } from 'react-mixpanel-browser';

function App() {
  const location = useLocation();
  return (
    <div>
      <MixpanelProvider>
      <BrowserView>
        {(location.pathname !== '/') && (location.pathname !== 'wholesale') && <Desktopnav />}
        </BrowserView>
      <Switch>
      <Route exact path="/">
          <FirstPage />
        </Route>
      </Switch>
      <div className="app-container bg-gray-100">
        <Switch>
        <Route path="/hardware">
          <HardwareHomepage />
        </Route>
        <Route path="/wholesale">
          <WholesaleHomepage />
        </Route>
        <Route path="/shops">
          <ShopsPage />
        </Route>
        <Route path="/products">
          <TopProducts/>
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/search/:query">
          <Searchresults />
        </Route>
        <Route path="/shop/:shopId">
          <Shopview />
        </Route>
        <Route path="/product/:productId">
          <Productview />
        </Route>
        <Route path="/category/:categoryID/:categoryName">
          <Categoryview />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/signin">
          <Signin />
        </Route>
        <Route path="/checkout">
          <Checkout />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/orders">
          <Orders />
        </Route>
        <Route path="/orderdetails">
          <Orderdetails />
        </Route>
        <Route path="/termsandconditions">
          <Termsandconds />
        </Route>
        </Switch>
        <MobileView>
        {location.pathname !== '/' && <MobileNav />}
        </MobileView>
     </div>
      </MixpanelProvider>
    </div>
    
  );
}

export default App;