import React, { useState } from "react";
import { Route, Switch, Link } from 'react-router-dom';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import './wizard.css';



const Checkout = () => {

  const [stp1, setStp1] = useState('');
  const [stp2, setStp2] = useState('');
  const [stp3, setStp3] = useState('');

  const setCurrentStep = (n) => {
    if(n === 1) {
      setStp1("current");
      setStp2("pending");
      setStp3("pending");
    }

    if(n === 2) {
      setStp1("pending");
      setStp2("current");
      setStp3("pending");
    }

    if(n === 3) {
      setStp1("pending");
      setStp2("pending");
      setStp3("current");
    }
  }

    return(
        <div className="d-flex flex-column-fluid">
  {/*begin::Container*/}
  <div className="container-fluid">
    <div className="card card-custom">
      <div className="card-body p-0">
        {/*begin::Wizard*/}
        <div className="wizard wizard-1" id="kt_wizard" data-wizard-state="first" data-wizard-clickable="false">
          {/*begin::Wizard Nav*/}
          <div className="wizard-nav border-bottom">
            <div className="wizard-steps p-8 p-lg-10">
              {/*begin::Wizard Step 2 Nav*/}
              <div className="wizard-step" data-wizard-type="step" data-wizard-state={stp1}>
                <div className="wizard-label">
                  <i className="wizard-icon flaticon-list" />
                  <h3 className="wizard-title">1.Enter your Payment</h3>
                </div>
                <span className="svg-icon svg-icon-xl wizard-arrow">
                  {/*begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
              </div>
              {/*begin::Wizard Step 1 Nav*/}
              <div className="wizard-step" data-wizard-type="step" data-wizard-state={stp2}>
                <div className="wizard-label">
                  <i className="wizard-icon flaticon-bus-stop" />
                  <h3 className="wizard-title">2. Enter your Location</h3>
                </div>
                <span className="svg-icon svg-icon-xl wizard-arrow">
                  {/*begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
              </div>
              {/*end::Wizard Step 1 Nav*/}
              {/*end::Wizard Step 2 Nav*/}
              {/*begin::Wizard Step 3 Nav*/}
              {/*end::Wizard Step 3 Nav*/}
              {/*begin::Wizard Step 4 Nav*/}
              <div className="wizard-step" data-wizard-type="step" data-wizard-state={stp3}>
                <div className="wizard-label">
                  <i className="wizard-icon flaticon-truck" />
                  <h3 className="wizard-title">3. Wait for your Order</h3>
                </div>
                <span className="svg-icon svg-icon-xl wizard-arrow">
                  {/*begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
              </div>
              {/*end::Wizard Step 4 Nav*/}
            </div>
          </div>
          {/*end::Wizard Nav*/}
          {/*begin::Wizard Body*/}
          <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div className="col-xl-12 col-xxl-7">
              {/*begin::Wizard Form*/}
              
                <Switch>
                  {/* <Route path="/">
                    <Step1 />
                  </Route> */}
                  <Route path="/checkout/step1">
                    <Step1  setCurrentStep = {setCurrentStep}/>
                  </Route>
                  <Route path="/checkout/step2">
                    <Step2  setCurrentStep = {setCurrentStep}/>
                  </Route>
                  <Route path="/checkout/step3">
                    <Step3  setCurrentStep = {setCurrentStep}/>
                  </Route>
                </Switch>
     
                {/*end::Wizard Actions*/}
                <div /><div /><div /><div />
              {/*end::Wizard Form*/}
            </div>
          </div>
          {/*end::Wizard Body*/}
        </div>
        {/*end::Wizard*/}
      </div>
      {/*end::Wizard*/}
    </div>
  </div>
  {/*end::Container*/}
</div>

    );
}

export default Checkout;