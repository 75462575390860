import React,{useEffect, Suspense, lazy} from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import {  setCurrentHomepage } from './homepageSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import { ReactComponent as MySvg } from './construction.svg';
import './hw.css';

// const Mobview = lazy(() => import('./mobile/Mobview'));
// const Desktopview = lazy(() => import('./desktop/Desktopview'));

const HardwareHomepage: React.FC = () => {

    const state:any = useSelector((state) => state);
    const currentHomepage = state.currentHomepage.currentHomepage;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentHomepage('hardware'));
    }, [setCurrentHomepage]);


    return(
            <div
                className="hw-svg-container"
            >     
            <img className="img-style rounded mb-1 mt-n10 cm-img" src={'https://ik.imagekit.io/kairos/Screenshot_from_2022-03-15_04.58.37_aGGztLOWT.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647309986321'} alt="Product"/>
            <div className="py-15 px-12 flex-wrap">
                <h1 className="text-primary text-center">Something awesome is coming!</h1><br />
                <p className="text-center"> Our Hardware feature is under construction, but we are ready to go! We are preparing something 
                    amazing and exiting for you.</p>
            </div>

        
        </div>
    );
}

export default HardwareHomepage;

