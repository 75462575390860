import React, {useState} from 'react';
import './Signin.css';
import logom from './main-logom.png';
import { useHistory } from 'react-router-dom';
import { makePostRequest } from '../../api/apiHandler';
import Loader from '../../../Loader';
import { Link } from "react-router-dom";
import { useMixpanel } from 'react-mixpanel-browser';


const Signin = () => {

    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState('/');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const mixpanel = useMixpanel();

    let history = useHistory();

    const [signInInfo, setSignInInfo] = useState(
      {
        email:'',
        password:''
      }
    )

    const handleInputChange = (event) => {

      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      

      setSignInInfo(prevState => ({
        ...prevState,
        [name]: value
        }))

    }
    

    const signIn = async () => {
        setLoading(true);

        if(mixpanel.config.token){

        }

        mixpanel.identify(signInInfo.email);

            mixpanel.people.set({ 
                email: signInInfo.email,
             });

             mixpanel.track('userEmail', {
                property:signInInfo.email
            })

        const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/auth/login`,
            {
              email:signInInfo.email,
              password:signInInfo.password
            }
          )
  
          if(result.status === 200){
            if(result.payload){
          
             
              setLoading(false);
  
              setErrorMessageVisible(false);
  
              localStorage.setItem("SKEY", result.payload.token);
              localStorage.setItem("loggedIn", "true");
              localStorage.setItem('userInfo', JSON.stringify(result.payload));
              history.push(localStorage.getItem("beforeAuthPath"));
            }else{
              setErrorMessageVisible(true)
              setErrorMessage('Something went wrong. Please try again');
            }
            setLoading(false);
          }else{
            setLoading(false);
            setErrorMessage('Username or password wrong. Please try again!');
            setErrorMessageVisible(true);
          }
          setLoading(false);
      }

      const handleClick = () => {
        history.push('/signup/');
    }

    return(
        <div className="signin-conainer">
            <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div className="login login-6 login-signin-on login-signin-on d-flex flex-column-fluid" id="kt_login">
            <div className="d-flex flex-column flex-lg-row flex-row-fluid text-center" style={{backgroundImage: 'url(bg-3.jpg)'}}>
            {/*begin:Aside*/}
            <div className="d-flex w-100 flex-center p-15">
                <div className="login-wrapper">
                {/*begin:Aside Content*/}
                <div className="text-dark-75">
                    <a href="#" className="">
                    <img src={logom} className="max-h-60px m-auto" alt="" />
                    </a>
                    <h3 className="mb-8 mt-22 font-weight-bold">A NEW SHOPPING EXPERIENCE</h3>
                    <p className="mb-15 text-muted font-weight-bold">Sign in or create a new account if tou have not done so to continue</p>
                    <button onClick={handleClick} type="button" id="kt_login_signup" className="btn btn-outline-primary btn-pill py-4 px-9 font-weight-bold">Sign up</button>
                </div>
                {/*end:Aside Content*/}
                </div>
            </div>
            {/*end:Aside*/}
            {/*begin:Divider*/}
            <div className="login-divider">
                <div />
            </div>
            {/*end:Divider*/}
            {/*begin:Content*/}
            <div className="d-flex h-100 w-100 flex-center p-15 position-relative overflow-hidden">
                <div className="login-wrapper">
                {/*begin:Sign In Form*/}
                <div className="login-signin">
                    <div className="text-center mb-10 mb-lg-20">
                    <h2 className="font-weight-bold">Sign In</h2>
                    <p className="text-muted font-weight-bold">Enter your username and password</p>
                    </div>
                    <form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_login_signin_form">
                    <div className="form-group mb-5 fv-plugins-icon-container">
                        <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" autoComplete="off" 
                            onChange={handleInputChange}
                            value={signInInfo.email}
                        />
                        <div className="fv-plugins-message-container" /></div>
                    <div className="form-group mb-5 fv-plugins-icon-container">
                        <input className="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" 
                            onChange={handleInputChange}
                            value={signInInfo.password}
                        />
                        <div className="fv-plugins-message-container" /></div>
                         {/*begin::Alert*/}
                         {errorMessageVisible && <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                              {/*begin::Icon*/}
                              {/*end::Icon*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                {/*begin::Title*/}
                                <h4 className="fw-bold text-danger">Something went wrong</h4>
                                {/*end::Title*/}
                                {/*begin::Content*/}
                                <span>{errorMessage}</span>
                                {/*end::Content*/}
                              </div>
                              {/*end::Wrapper*/}
                              {/*begin::Close*/}
                              {/*end::Close*/}
                            </div>}
                            {/*end::Alert*/}
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <div className="checkbox-inline">
                        <label className="checkbox m-0 text-muted">
                            <input type="checkbox" name="remember" />
                            <span />Remember me</label>
                        </div>
                </div>
                <div className="pb-lg-0 pb-5">
                            {loading ? <Loader/> : 
                              <button type="button" id="kt_login_signin_submit" className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                              onClick={signIn}
                            >Sign In</button>
                            }
                          </div>
                <input type="hidden" /><div /></form>
          </div>
        </div>
      </div>
      {/*end:Content*/}
    </div>
  </div>
  {/*end::Login*/}
</div>

        </div>
    );
}

export default Signin;