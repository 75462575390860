import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makePostRequest } from "../../api/apiHandler";
import Mobiletopnav from "../../common/navs/Mobiletopnav";
import Shopproducts from "./Shopproducts";

export interface urlParams {
    shopId:string
} 

const Shopview = () => {

    
    const state:any = useSelector((state) => state);

    let history = useHistory();

    const { shopId }:urlParams  = useParams();

    console.log(shopId);

    const currentHomepage = state.currentHomepage.currentHomepage;
    
    const [shop, setShop] = useState<any>(
        {

        }
    );

    useEffect(() => {

        (async () => {

            const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/getOneShop`,
                {
                    shop_id:Number(shopId)
                }
            )
           
            if(result.status === 200){
                setShop(result.payload.shop);
            }

        })()
    
        return () => {
            
        }
      }, [])

    return(
        <div>
            <div className="p-5 mb-30">
            <div className="card card-custom gutter-b">
                <div className="card-body">
                    <div className="d-flex">
                    {/*begin: Pic*/}
                    <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                        <div className="symbol symbol-50 symbol-lg-120">
                        <img alt="Pic" src={shop.profileImageUrl || 'https://dynamic.brandcrowd.com/asset/logo/92ad8d15-31cb-4ccc-bc37-1e1c7e61c552/logo?v=637419791231900000'} />
                        </div>
                        <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                        <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                        </div>
                    </div>
                    {/*end: Pic*/}
                    {/*begin: Info*/}
                    <div className="flex-grow-1">
                        {/*begin: Title*/}
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="mr-3">
                            {/*begin::Name*/}
                            <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{shop.name}
                            <i className="flaticon2-correct text-success icon-md ml-2" /></a>
                            {/*end::Name*/}
                            {/*begin::Contacts*/}
                            <div className="d-flex flex-wrap my-2">
                            {/* <a href="jeffs@gmail.com" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"> */}
                                {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                    </g>
                                </svg> */}
                                {/*end::Svg Icon*/}
                                {/* </span>jeffs@gmail.com</a> */}
                            <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <mask fill="white">
                                        <use xlinkHref="#path-1" />
                                    </mask>
                                    <g />
                                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                                </span>{currentHomepage} Shop</a>
                            <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                                </span>Kenya</a>
                            </div>
                            {/*end::Contacts*/}
                        </div>
                        <div className="my-lg-0 my-1">
                            <div className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">#General</div>
                            <div className="btn btn-sm btn-primary font-weight-bolder text-uppercase">#Shop</div>
                        </div>
                        </div>
                        {/*end: Title*/}
                        {/*begin: Content*/}
                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                        <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">{shop.description}</div>
                        <div className="d-flex flex-wrap align-items-center py-2">
                            <div className="flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0">
                            <span className="font-weight-bold">Customer Satisfaction</span>
                            <div className="progress progress-xs mt-2 mb-2">
                                <div className="progress-bar bg-primary" role="progressbar" style={{width: '90%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                            <span className="font-weight-bolder text-dark">90%</span>
                            </div>
                        </div>
                        </div>
                        {/*end: Content*/}
                    </div>
                    {/*end: Info*/}
                    </div>
                    {/* <div className="separator separator-solid my-7" /> */}
                    <div className="card-toolbar mt-8">
                    <ul className="nav nav-tabs nav-bold nav-tabs-line">
                        <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#kt_tab_pane_1_4">
                            <span className="nav-icon"><i className="flaticon2-chat-1" /></span>
                            <span className="nav-text">Products</span>
                        </a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#kt_tab_pane_2_4">
                            <span className="nav-icon"><i className="flaticon2-drop" /></span>
                            <span className="nav-text">Reviews</span>
                        </a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#kt_tab_pane_2_4">
                            <span className="nav-icon"><i className="flaticon2-information" /></span>
                            <span className="nav-text">About</span>
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                
                    <div className="">
                    <div className="tab-pane fade show active" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
                        <Shopproducts shopId={shopId} />
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_2_4" role="tabpanel" aria-labelledby="kt_tab_pane_2_4">
                        ...
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_3_4" role="tabpanel" aria-labelledby="kt_tab_pane_3_4">
                        ...
                    </div>
                    </div>
                
                </div>

        </div>
        </div>
    );
}

export default Shopview;