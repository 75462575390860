import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makePostRequest } from "../../api/apiHandler";
import toast, { Toaster } from 'react-hot-toast';
import Loader from "../../../Loader";

const Step2 = ({setCurrentStep }:any) => {

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deliveryInfo, setdeliveryInfo] = useState(
    {
      county: '',
      town: '',
      landMark: ''
    }
  )

  useEffect(() => {
    setCurrentStep(2);
  }, []);

  const handleInputChange = (event) => {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    

    setdeliveryInfo(prevState => ({
      ...prevState,
      [name]: value
      }))

  }

  const goToNext = async () => {
    

    if(!deliveryInfo.county || !deliveryInfo.town || !deliveryInfo.landMark){
      toast.error('Enter all fields!');
      return;
    }

    setLoading(true);
    const result:IApiResponse = await makePostRequest(`${process.env.REACT_APP_API_URL}/shop/addDeliveryAddress`,
      {
        county: deliveryInfo.county,
        town: deliveryInfo.town,
        landMark: deliveryInfo.landMark
      }
    )
    
    if(result.status === 200){
      setLoading(false);
      toast.success('Successfully added!');
      history.push("/checkout/step3");
    }else{
      setLoading(false);
      toast.error('Address not added please try again!');
    }
    setLoading(false);
  }

    return (
  <div>
    <Toaster/>
  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
  <div className="col-xl-12 col-xxl-7">
    {/*begin: Wizard Form*/}
    <form className="form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
      {/*begin: Wizard Step 1*/}
      <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <h4 className="mb-10 font-weight-bold text-dark">Enter Your Delivery Address</h4>
        {/*begin::Input*/}
        <div className="form-group fv-plugins-icon-container has-success">
          <label>Town</label>
          <input 
            onChange={handleInputChange}
            value={deliveryInfo.town}
          type="text" className="form-control form-control-solid form-control-lg" name="town" placeholder="town" />
          <span className="form-text text-muted">Please enter your town.</span>
          <div className="fv-plugins-message-container" /></div>
        {/*end::Input*/}
        {/*begin::Input*/}
        {/*end::Input*/}
        <div className="row">
          <div className="col-xl-6">
            {/*begin::Select*/}
            <div className="form-group fv-plugins-icon-container has-success">
              <label>County</label>
              <select 
              onChange={handleInputChange}
              value={deliveryInfo.county}
              name="county" className="form-control form-control-solid form-control-lg">
                <option value="">Select</option>
                <option value="baringo">Baringo</option>
                <option value="bomet">Bomet</option>
                <option value="bungoma">Bungoma</option>
                <option value="busia">Busia</option>
                <option value="elgeyo marakwet">Elgeyo Marakwet</option>
                <option value="embu">Embu</option>
                <option value="garissa">Garissa</option>
                <option value="homa bay">Homa Bay</option>
                <option value="isiolo">Isiolo</option>
                <option value="kajiado">Kajiado</option>
                <option value="kakamega">Kakamega</option>
                <option value="kericho">Kericho</option>
                <option value="kiambu">Kiambu</option>
                <option value="kilifi">Kilifi</option>
                <option value="kirinyaga">Kirinyaga</option>
                <option value="kisii">Kisii</option>
                <option value="kisumu">Kisumu</option>
                <option value="kitui">Kitui</option>
                <option value="kwale">Kwale</option>
                <option value="laikipia">Laikipia</option>
                <option value="lamu">Lamu</option>
                <option value="machakos">Machakos</option>
                <option value="makueni">Makueni</option>
                <option value="mandera">Mandera</option>
                <option value="meru">Meru</option>
                <option value="migori">Migori</option>
                <option value="marsabit">Marsabit</option>
                <option value="mombasa">Mombasa</option>
                <option value="muranga">Muranga</option>
                <option value="nairobi">Nairobi</option>
                <option value="nakuru">Nakuru</option>
                <option value="nandi">Nandi</option>
                <option value="narok">Narok</option>
                <option value="nyamira">Nyamira</option>
                <option value="nyandarua">Nyandarua</option>
                <option value="nyeri">Nyeri</option>
                <option value="samburu">Samburu</option>
                <option value="siaya">Siaya</option>
                <option value="taita taveta">Taita Taveta</option>
                <option value="tana river">Tana River</option>
                <option value="tharaka nithi">Tharaka Nithi</option>
                <option value="trans nzoia">Trans Nzoia</option>
                <option value="turkana">Turkana</option>
                <option value="uasin gishu">Uasin Gishu</option>
                <option value="vihiga">Vihiga</option>
                <option value="wajir">Wajir</option>
                <option value="pokot">West Pokot</option>
              </select>
              <div className="fv-plugins-message-container" /></div>
            {/*end::Select*/}
          </div>
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group fv-plugins-icon-container has-success">
              <label>Landmark</label>
              <input 
              onChange={handleInputChange}
              value={deliveryInfo.landMark}
              type="text" className="form-control form-control-solid form-control-lg" name="landMark" placeholder="Landmark" />
              <span className="form-text text-muted">Landmark is your local area name.</span>
              <div className="fv-plugins-message-container" /></div>
            {/*end::Input*/}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          <button type="button" className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Previous</button>
        </div>
        <div>
          <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-submit">Submit</button>
          {loading ? <Loader/> : 
          <button 
          onClick={() => goToNext()}
          type="button" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-next">Next</button>
          }
          </div>
      </div>
      {/*end: Wizard Actions*/}
      <div /><div /></form>
    {/*end: Wizard Form*/}
  </div>
</div>
      <br/>
      <br /><br />
        </div>
    );
}

export default Step2;