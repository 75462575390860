import React, { useEffect } from 'react';
import './Cart.css';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, updateCart, updateQuantity, viewCartItems } from './cartSlice';
import { Link } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Loader';

const Cart = () => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(viewCartItems());
	}, [dispatch]);

	const state:any = useSelector((state) => state);
	const cart = state.cart;
	const items = cart.items;
	const history = useHistory();

	const onContinueSchopping = () => {
		history.push(localStorage.getItem("shopPath"), '/');
	}

	const goToCheckout = () => {
		if(cart.totalAmount < 1 || cart.pending || cart.failed){
			return;
		}else{
			history.push("checkout/step1", '/');
		}
	}

    return(
        <div className="p-5">
            <div className="flex-row-fluid ml-lg-8">
										<div className="card card-custom gutter-b">
											
											<div className="card-header flex-wrap border-0 pt-6 pb-0">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label font-weight-bolder font-size-h3 text-dark">My Shopping Cart</span>
												</h3>
												<div className="card-toolbar">
													<div className="dropdown dropdown-inline">
														<button
														onClick={() => {onContinueSchopping()}}
														className="btn btn-primary font-weight-bolder font-size-sm">Continue Shopping</button>
													</div> 
												</div>
											</div>
											
											<div className="card-body">
											{!(items.length > 0) && <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
															<div className="alert-icon">
																<span className="svg-icon svg-icon-primary svg-icon-xl">
																{/*begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Tools/Compass.svg*/}
																<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<rect x={0} y={0} width={24} height={24} />
																	<path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
																	<path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
																	</g>
																</svg>
																{/*end::Svg Icon*/}
																</span>
															</div>
															<div className="alert-text">You have no products in cart! Continue Shopping and add!
																{/* <a className="font-weight-bold" href="http://bootstrap-notify.remabledesigns.com/" target="_blank">Home</a>or 
																<a className="font-weight-bold" href="https://github.com/mouse0270/bootstrap-notify/" target="_blank">Github Repo</a>.</div> */}
															</div>
															</div>
															}
												<div className="table-responsive">
													<table className="table">
														
														<thead>
															<tr>
																<th>Product</th>
																<th className="text-center">Qty</th>
																<th className="text-right">Price</th>
																<th className="text-right">Subtotal</th>
																<th className="text-right"></th>
															</tr>
														</thead>
													
														<tbody>
	
															{items.length > 0 && items.map((item, index) =>{
																return (
																	<tr key={index}>
																		<td className="d-flex align-items-center font-weight-bolder">
																			
																			<div className="symbol symbol-60 flex-shrink-0 mr-4 bg-light">
																				<div className="symbol-label">
																					<img className="rounded" src={item.product.thumbUrl} alt="cart img" />
																				</div>
																			</div>
																		
																			<div className="text-dark text-hover-primary">{item.product.name}</div>
																		</td>
																		<td className="text-center align-middle">
																			{/* <div className="btn btn-xs btn-light-primary btn-icon">
																				<i classNinitialStateame="ki ki-minus icon-xs"></i>
																			</div> */}
																			<div className="quantity-input-container d-flex justify-content-center">
																				<input type="number" className="form-control quantity-input mr-3"
																					value={item.quantity}
																					onChange={(input => dispatch(updateCart(input.target.value, item.id, item.product.id)))}
																				/> 
																			</div>
																			{/* <div className="btn btn-xs btn-light-primary btn-icon">
																				<i className="ki ki-plus icon-xs"></i>
																			</div> */}
																		</td>
																		<td className="text-right align-middle font-size-h5">Ksh. {item.product.price}</td>
																		<td className="text-right align-middle font-weight-bolder font-size-h5 text-primary">Ksh. {item.product.price * item.quantity}</td> 
																		<td className="text-right align-middle">
																			<button 
																			onClick={() => dispatch(removeFromCart(item.id))}
																			className="btn btn-light-danger btn-sm font-weight-bold mr-2">
																				<i className="ki ki-bold-close icon-sm"></i> remove
																			</button>
																		</td>
																	</tr>
																);
															})}
															<BrowserView>
															<tr>
																<td colSpan={2}></td>
																<td className="font-weight-bolder font-size-h4 text-right">Total</td>
																{cart.pending && <div className="d-flex justify-content-end"><Loader /></div>}
																{!cart.pending && <td className="font-weight-bolder font-size-h3 text-right text-primary">Ksh. {cart.totalAmount}</td>}
														
															</tr>
															<tr>
																<td colSpan={4} className="border-0 text-muted text-right pt-0">Excludes Delivery. GST Included</td>
															</tr>
															</BrowserView>
															
															<BrowserView>
															<tr>
																<td colSpan={2} className="border-0 pt-10">
																	{/* <form>
																		<div className="form-group row">
																			<div className="col-md-3 d-flex align-items-center">
																				<label className="font-weight-bolder">Apply Voucher</label>
																			</div>
																			<div className="col-md-7">
																				<div className="input-group w-100">
																					<input type="text" className="form-control" placeholder="Voucher Code" />
																					<div className="input-group-append">
																						<button className="btn btn-secondary" type="button">Apply</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form> */}
																</td>
																<td colSpan={2} className="border-0 text-right pt-10">
																	<Link to='/checkout/step1'><button className="btn btn-primary font-weight-bolder px-8">Checkout</button></Link>
																</td>
															</tr>
															</BrowserView>															
														</tbody>
													</table>
												</div>
												<MobileView>
												<div className="mt-10">
												<div>
													<div className="font-weight-bolder font-size-h4 text-right">Total</div>
													{cart.pending && <div className="d-flex justify-content-end"><Loader /></div>}
													{!cart.pending && <div className="font-weight-bolder font-size-h3 text-right text-primary">Ksh. {cart.totalAmount}</div>}
												</div>
												<button 
												onClick={() => goToCheckout()}
												className="btn btn-primary btn-lg font-weight-bolder px-8">Checkout</button>
												</div>
												</MobileView>
				
											</div>
										</div>
										
									</div>
        </div>
    );
}

export default Cart;

